<template>
	<div class="app-main-layout" :class="[{openedMenu: openedMenu}, template]">

		<Header @openMenuFunc="openMenuFunc" :openedMenu="openedMenu"/>

		<main class="app-content">
			<div class="app-page" :class="[{withoutBanner: !firstBlockBanner}, {'oldTemplate': oldTemplate}]">

				<div class="container pageHeader" v-if="h1">
					<BreadCrumbs v-if="showBreadcrumb"></BreadCrumbs>

					<div class="mainTitle2">
						<div class="h1" v-html="h1"></div>
						<p class="description" v-html="description" v-if="description"></p>
					</div>
				</div>

				<router-view />
			</div>
		</main>

		<Footer />
		<NextCase v-if="casePage && nextCase && (nextCase == 'abd' || nextCase == 'medar' || nextCase == 'centrserdce' || nextCase == 'dymov' || nextCase == 'mpkabel' || nextCase == 'kk')" :caseName="nextCase" @openNextCase="openNextCase"></NextCase>

		<Menu :openedMenu="openedMenu" @openMenuFunc="openMenuFunc" :active="openedMenu"/>
	</div>
</template>

<style lang="scss">
	@import "../assets/fonts/icomoon/style.css";
	@import "animate.css/animate.min";
</style>
<style lang="sass">
	@import '@/assets/new_sass/fonts'
	@import '@/assets/new_sass/main'
	@import '@/assets/new_sass/frontend'
	@import '@/assets/new_sass/media'
	@import '@/assets/new_sass/FullPageSection'
</style>

<script>
	import projectsData from "@/assets/json/projects.json";
	export default {
		name: 'NewLayout',
		data: () => ({
			openedMenu: false,
			h1: '',
			description: '',
			showBreadcrumb: false,
			firstBlockBanner: false,
			template: '',
			oldTemplate: false,
			casePage: false,
			caseName: '',
			projectsData,
			nextCase: '',
			nextCasePath: '',
			nextPageAction: false
		}),
		mounted(){
			this.showBreadcrumb = this.$route.meta.showBreadcrumb;
			if(this.$route.meta.template){
				this.template = this.$route.meta.template;
			} else{
				this.template = '';
			}
			if(this.$route.meta.h1){
				this.h1 = this.$route.meta.h1;
			} else{
				this.h1 = '';
			}
			if(this.$route.meta.description){
				this.description = this.$route.meta.description;
			} else{
				this.description = '';
			}
			if(this.$route.meta.firstBlockBanner){
				this.firstBlockBanner = this.$route.meta.firstBlockBanner;
			} else{
				this.firstBlockBanner = false
			}
			if(this.$route.meta.oldTemplate){
				this.oldTemplate = this.$route.meta.oldTemplate;
			} else{
				this.oldTemplate = false
			}
			if(this.$route.meta.casePage){
				this.casePage = this.$route.meta.casePage;
			} else{
				this.casePage = false
			}
			if(this.$route.meta.caseName){
				this.caseName = this.$route.meta.caseName;
			} else{
				this.caseName = false
			}

			var wow = new WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				live: true,
				mobile: false
			});
			wow.init();

			this.initAnimationBlocks();

			this.getNextCase();
		},
		watch: {
			'$route': function (refreshPage) {
				this.showBreadcrumb = this.$route.meta.showBreadcrumb;
				if(this.$route.meta.template){
					this.template = this.$route.meta.template;
				} else{
					this.template = '';
				}
				if(this.$route.meta.h1){
					this.h1 = this.$route.meta.h1;
				} else{
					this.h1 = '';
				}
				if(this.$route.meta.description){
					this.description = this.$route.meta.description;
				} else{
					this.description = '';
				}
				if(this.$route.meta.firstBlockBanner){
					this.firstBlockBanner = this.$route.meta.firstBlockBanner;
				} else{
					this.firstBlockBanner = false
				}
				if(this.$route.meta.oldTemplate){
					this.oldTemplate = this.$route.meta.oldTemplate;
				} else{
					this.oldTemplate = false
				}
				if(this.$route.meta.casePage){
					this.casePage = this.$route.meta.casePage;
				} else{
					this.casePage = false
				}
				if(this.$route.meta.caseName){
					this.caseName = this.$route.meta.caseName;
				} else{
					this.caseName = false
				}

				if(this.openedMenu){
					var comp = this;
					setTimeout(function(){
						document.body.style.overflow = "";
						document.body.style.position = "";
						document.body.style.width = "";
						document.body.style.left = "";
						document.body.style.top = "";
						document.querySelector("html").style.overflow = "";
						document.querySelector("html").style.position = "";
						document.querySelector("html").style.width = "";
						document.querySelector("html").style.left = "";
						window.scrollTo({top: comp.topPosition});
					}, 400);
					this.openedMenu = !this.openedMenu
				}

				if(this.nextPageAction){
					window.scrollTo({top: 0});
					this.nextPageAction = false;
				}

				var comp = this;
				setTimeout(function(){
					comp.animateVisibleBlocks();
				}, 1500);
				
				this.getNextCase();
			}
		},
		methods: {
			getNextCase: function() {
				var comp = this;

				if(comp.casePage){
					var searchedCase = false;

					comp.nextCase = '';
					comp.nextCasePath = '';

					projectsData.forEach( function(element, index) {
						if(searchedCase && element.caseName){
							comp.nextCase = element.caseName;
							comp.nextCasePath = element.link;
							searchedCase = false;
						}
						if(element.caseName == comp.caseName){
							searchedCase = true;
						}
					});
				}
			},
			openNextCase: function(){
				var comp = this;

				comp.nextPageAction = true;
				comp.$router.push({ path: comp.nextCasePath });
			},
			openMenuFunc: function() {
				if(this.openedMenu){
					var comp = this;
					setTimeout(function(){
						document.body.style.overflow = "";
						document.body.style.position = "";
						document.body.style.width = "";
						document.body.style.left = "";
						document.body.style.top = "";
						document.querySelector("html").style.overflow = "";
						document.querySelector("html").style.position = "";
						document.querySelector("html").style.width = "";
						document.querySelector("html").style.left = "";
						window.scrollTo({top: comp.topPosition});
					}, 50);
				} else{
					this.topPosition = window.scrollY;
					document.body.style.overflow = "hidden";
					document.body.style.position = "fixed";
					document.body.style.width = "100%";
					document.body.style.left = "0";
					document.body.style.top = -this.topPosition + "px";
					document.querySelector("html").style.overflow = "hidden";
					document.querySelector("html").style.position = "fixed";
					document.querySelector("html").style.width = "100%";
					document.querySelector("html").style.left = "0";
				}

				this.openedMenu = !this.openedMenu
			},
			initAnimationBlocks: function(){
				window.onload = function() {
					this.animateVisibleBlocks();
				}.bind(this);

				window.addEventListener('scroll', function(){
					this.animateVisibleBlocks();
				}.bind(this));
			},
			animateVisibleBlocks: function() {
				var blocks = document.getElementsByClassName("animationBlockFadeUp");
				var scrollTop = document.documentElement.scrollTop;
				var windowHeight = window.innerHeight;
				var windowBottomPos = scrollTop + windowHeight;

				Array.from(blocks).forEach(function(element, index) {
					var elementPosTop = element.getBoundingClientRect().top + document.documentElement.scrollTop;

					if(windowBottomPos > elementPosTop + 100){
						setTimeout(function(){
							element.style.transform = 'matrix(1, 0, 0, 1, 0, 0)';
							element.style.opacity = '1';
						}, 500);
					}
				});
			}
		},
		components: {
			Header: () => import('@/components/new/Header'),
			Footer: () => import('@/components/new/Footer'),
			Menu: () => import('@/components/new/Menu'),
			BreadCrumbs: () => import('@/components/new/BreadCrumbs'),
			NextCase: () => import('@/components/new/NextCase')
		}
	}
</script>